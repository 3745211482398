<template>
  <div class="actions">
    <router-link
      :to="{name: 'animal_detail', params: {uid: object.uid} }"
      class="btn btn-secondary btn-sm"
    >
      <i class="icofont icofont-eye" />
    </router-link>

    <router-link
      :to="{name: 'animal_edit', params: {uid: object.uid} }"
      class="btn btn-primary btn-sm"
      v-if="is_veto || is_admin || is_assistant_veto_major"
    >
      <i class="icofont icofont-pencil" />
    </router-link>
    
    <a
      href="javascript:void(0)"
      class="btn btn-third btn-sm"
      @click.prevent="deletingAnimal"
      v-if="is_veto || is_admin"
    > <i class="icofont icofont-close" /></a>
  </div>
</template>



<script>
import { mapGetters } from 'vuex'
import { DELETE_ANIMAL } from '../../../graphql/identification';
export default {
    props: {
        object: {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    methods: {
        deletingAnimal(){
          this.$apollo.mutate({
                mutation: DELETE_ANIMAL,
                variables: {
                    "uid": this.object.uid
                },
                update: (data) => {
                    if(data) console.log(`Animal ${this.object.uid} deleted successfully`)
                    else console.log(`Error in deletting animal ${this.object.uid}`)
                }
              })
        }
    },
    computed: {
      ...mapGetters({
        is_super_admin: 'auth/is_super_admin',
        is_admin: 'auth/is_admin',
        is_veto: 'auth/is_veterinaire',
        is_assistant_veto_major: 'auth/is_assistant_veto_major'
      })
    }

}

</script>
<style lang="scss">

</style>